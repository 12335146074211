// Node Modules
import React from 'react'
// import { Helmet } from 'react-helmet'
import SDLocalBusiness from "../StructuredData/SDLocalBusiness"
import { navigate } from "gatsby"

// Components
import Header from './Header'
import Footer from './Footer'

// Assets

const Layout = ({ children, location, isOpacity = false }) => {
    // Redirect Unsupported Browser (IE)
    const isSSR = typeof window === "undefined"
    let isIE
    !isSSR && (isIE = /*@cc_on!@*/false || !!document.documentMode)
    isIE && (navigate(`/browser/`))
    return (
        <>
            <SDLocalBusiness />
            <div id="top" className="w-full">
                <Header location={location} isOpacity={isOpacity} />
            </div>
            {children}
            <Footer />
        </>
    )
}

export default Layout