// Node Modules
import React from 'react'
import { Link, graphql, useStaticQuery } from "gatsby"

// Assets
import IconMail from '../../images/IconMail'
import IconPhone from '../../images/IconPhone'
import IconFacebook from '../../images/IconFacebook'
import IconTwitter from '../../images/IconTwitter'
import IconInstagram from '../../images/IconInstagram'
import IconLinkedin from '../../images/IconLinkedin'
import logoSquare from '../../images/nine-lives-logo-square.svg'

const Footer = () => {
  const { settingsgeneral, settingsfooter, menu, documentmenu } = useStaticQuery(graphql`
      query {
        settingsgeneral: file(relativePath: {eq: "settings/general.md"}) {
            childMarkdownRemark {
              frontmatter {
                settingtelephonenumber
                settingemail
                settingsiteorg
                settingsitetwitteruser
                settingsitefacebookuser
                settingsiteinstagramuser
                settingsitelinkedin
              }
            }
          }
          settingsfooter: file(relativePath: {eq: "settings/footer.md"}) {
            childMarkdownRemark {
              frontmatter {
                settingfootlnktxt
                settingfootconttxt
                settingfootdoctxt
                settingfootsoctxt
              }
            }
          }
          menu: allMarkdownRemark(
            filter: {fileAbsolutePath: {regex: "/(menu)/.*[.]md$/"}, frontmatter: {menuname: {ne: "dummy-menu-01-02-1999"}}}
            sort: {fields: frontmatter___menuposition, order: ASC}
          ) {
            edges {
              node {
                fields {
                  slug
                }
                id
                frontmatter {
                  menuname
                  menulink {
                    type
                    menupagelinktitle
                    menupagelink
                    menufeaturelinktitle
                    menufeaturelink
                    menucustomlinktitle
                    menucustomlink
                  }
                }
              }
            }
          }
          documentmenu: allMarkdownRemark(
            filter: {fileAbsolutePath: {regex: "/(page)/.*[.]md$/"}, frontmatter: {document: {eq: true}}}
            sort: {fields: frontmatter___title, order: DESC}
          ) {
            edges {
              node {
                fields {
                  slug
                }
                id
                frontmatter {
                  title
                  document
                }
              }
            }
          }
      }
    `)
  const settingsgen = settingsgeneral.childMarkdownRemark.frontmatter
  const settingfoot = settingsfooter.childMarkdownRemark.frontmatter
  return (
    <>
      <footer className="relative bg-template-black100 overflow-hidden py-6">
        <div className="container mx-auto text-white flex flex-wrap justify-start xs:px-4 sm:px-4 md:px-10">
          <div className="w-6/12 sm:w-4/12 md:w-3/12">
            {menu.edges.length > 0 && (
              <h2 className="text-sm leading-4 uppercase font-medium mb-6 text-template-greenMedium">{settingfoot.settingfootlnktxt}</h2>
            )}
            <ul className="list-none">
              {menu.edges.map(edge => {
                const menuitem = edge.node.frontmatter.menulink[0]
                let menutitle = ''
                let menuurl = ''
                menuitem.menupagelinktitle && (menutitle = menuitem.menupagelinktitle)
                menuitem.menufeaturelinktitle && (menutitle = menuitem.menufeaturelinktitle)
                menuitem.menucustomlinktitle && (menutitle = menuitem.menucustomlinktitle)
                menuitem.menupagelink && (menuurl = menuitem.menupagelink)
                menuitem.menufeaturelink && (menuurl = menuitem.menufeaturelink)
                menuitem.menucustomlink && (menuurl = menuitem.menucustomlink)
                return (
                  <li key={edge.node.id}>
                    {!menuurl.startsWith("http") ?
                      <Link to={menuurl} className="flex items-center text-xs md:text-sm md:py-1 leading-7 font-light hover:text-template-orangeLight transition duration-200 ease-in-out">{menutitle}</Link>
                      :
                      <a href={menuurl} target="_blank" rel="noreferrer" className="flex items-center text-xs md:text-sm md:py-1 leading-7 font-light hover:text-template-orangeLight transition duration-200 ease-in-out">{menutitle}</a>
                    }
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="w-6/12 sm:w-4/12 md:w-3/12">
            {documentmenu.edges.length > 0 && (
              <h2 className="text-sm leading-4 uppercase font-medium mb-6 text-template-greenMedium">{settingfoot.settingfootdoctxt}</h2>
            )}
            <ul className="list-none mb-3">
              {documentmenu.edges.filter(edge => edge.node.frontmatter.menuname !== "dummy-page-01-02-1999").map(edge => {
                const document = edge.node.frontmatter
                return (
                  <li key={edge.node.id}>
                    <Link to={edge.node.fields.slug} className="flex items-center text-xs md:text-sm md:py-1 leading-7 font-light hover:text-template-orangeLight transition duration-200 ease-in-out">{document.title}</Link>
                  </li>
                )
              })}
            </ul>
          </div>

          {/* <!-- Col-3 --> */}
          <div className="w-6/12 sm:w-4/12 md:w-3/12">
            {/* <!-- Col Title --> */}
            <h2 className="text-sm leading-4 uppercase font-medium mb-6 text-template-greenMedium">
              {settingfoot.settingfootconttxt}
            </h2>

            {/* <!-- Links --> */}
            <a href={"mailto:" + settingsgen.settingemail} className="flex items-center text-xs md:text-sm md:py-1 leading-7 font-light hover:text-template-orangeLight transition duration-200 ease-in-out">
              <IconMail />&nbsp;Email{/*{settingsgen.settingemail} */}
            </a>
            <a href={"tel:" + settingsgen.settingtelephonenumber} className="flex items-center text-xs md:text-sm md:py-1 leading-7 font-light hover:text-template-orangeLight transition duration-200 ease-in-out">
              <IconPhone />&nbsp;{settingsgen.settingtelephonenumber}
            </a>
          </div>

          {/* <!-- Col-3 --> */}
          <div className="w-6/12 sm:w-4/12 md:w-3/12">
            {/* <!-- Col Title --> */}
            <h2 className="text-sm leading-4 uppercase font-medium mb-6 text-template-greenMedium">
              {settingfoot.settingfootsoctxt}
            </h2>

            {/* <!-- Links --> */}
            {settingsgen.settingsitefacebookuser &&
              <a href={settingsgen.settingsitefacebookuser}
                target="_blank" rel="noreferrer" aria-label="Facebook"
                className="flex items-center text-xs md:text-sm md:py-1 leading-7 font-light hover:text-template-orangeLight transition duration-200 ease-in-out">
                <IconFacebook />&nbsp;Facebook
              </a>
            }
            {settingsgen.settingsitetwitteruser &&
              <a href={settingsgen.settingsitetwitteruser}
                target="_blank" rel="noreferrer" aria-label="Twitter"
                className="flex items-center text-xs md:text-sm md:py-1 leading-7 font-light hover:text-template-orangeLight transition duration-200 ease-in-out">
                <IconTwitter />&nbsp;Twitter
              </a>
            }
            {settingsgen.settingsiteinstagramuser &&
              <a href={settingsgen.settingsiteinstagramuser}
                target="_blank" rel="noreferrer" aria-label="Instagram"
                className="flex items-center text-xs md:text-sm md:py-1 leading-7 font-light hover:text-template-orangeLight transition duration-200 ease-in-out">
                <IconInstagram />&nbsp;Instagram
              </a>
            }
            {settingsgen.settingsitelinkedin &&
              <a href={settingsgen.settingsitelinkedin}
                target="_blank" rel="noreferrer" aria-label="Linkedin"
                className="flex items-center text-xs md:text-sm md:py-1 leading-7 font-light hover:text-template-orangeLight transition duration-200 ease-in-out">
                <IconLinkedin />&nbsp;Linkedin
              </a>
            }
          </div>
        </div>

        {/* <!-- Copyright Bar --> */}
        <div className="pt-2 container mx-auto">
          <div className="flex flex-col md:flex-row justify-between py-2 xs:px-4 sm:px-4 md:px-10 m-auto border-t-2 border-solid border-template-greenMedium text-gray-400 text-xs md:text-sm leading-5">
            <div className="mt-2">
              &#169; {settingsgen.settingsiteorg} {(new Date().getFullYear())}
            </div>
            <div className="mt-2">
              <img src={logoSquare} alt={settingsgen.settingsiteorg} className="w-8" />
            </div>
            <div className="mt-2">
              <small><a href="https://www.rokitmedia.co.uk" aria-label="Rokit Media Web Design">Website Designed by Rokit Media</a></small>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer