import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ strDescription, lang, strTitle, strOgType, strImage, strAuthor, meta }) {
  const { siteconfigsettings } = useStaticQuery(
    graphql`
      query {
        siteconfigsettings: file(relativePath: {eq: "settings/general.md"}) {
          id
          childMarkdownRemark {
            settingsitedefimageLocal {
              publicURL
          }
            frontmatter {
              settingsitetitle
              settingsitetitletemplate
              settingsitedescription
              settingsiteauthor
              settingsiteurl
              settingsiteogtype
            }
          }
        }
      }
    `
  )

  const siteConfig = siteconfigsettings.childMarkdownRemark.frontmatter
  const metaDescription = strDescription || siteConfig.settingsitedescription
  const metaTitleTemplate = siteConfig.settingsitetitletemplate
  const metaTitle = strTitle || siteConfig.settingsitetitle
  const siteUrl = siteConfig.settingsiteurl
  const ogType = strOgType || siteConfig.settingsiteogtype
  const metaImage = strImage || siteconfigsettings.childMarkdownRemark.settingsitedefimageLocal.publicURL
  const author = strAuthor || siteConfig.settingsiteauthor

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s · ` + metaTitleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: ogType,
        },
        {
          property: `og:image`,
          content: siteUrl + metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: siteUrl + metaImage,
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  strDescription: PropTypes.string,
  lang: PropTypes.string,
  strTitle: PropTypes.string,
  strOgType: PropTypes.string,
  strImage: PropTypes.string,
  strAuthor: PropTypes.string,
}

export default Seo