import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// JSON-LD

const SDLocalBusiness = () => {

    // feature: article
    // https://schema.org/ProfessionalService
    // https://developers.google.com/search/docs/data-types/local-business

    const { site } = useStaticQuery(
        graphql`
        query {
            site: file(relativePath: {eq: "settings/general.md"}) {
                id
                childMarkdownRemark {
                    defaultImage: settingsitedefimageLocal {
                        publicURL
                    }
                    defaultLogo: settinglogoLocal {
                        publicURL
                      }
                  frontmatter {
                    defaultUrl: settingsiteurl
                    defaultName: settingsiteorg
                    defaultTitle: settingsitetitle
                    defaultDescription: settingsitedescription
                    defaultAuthor: settingsiteauthor
                    streetAddress: settingstreetaddress
                    addressLocality: settingaddresslocality
                    addressRegion: settingaddressregion
                    postalCode: settingpostalcode
                    addressCountry: settingaddresscountry
                    telephoneNumber: settingtelephonenumber
                    contactURL: settingsiteurl
                    longitude: settinglongitude
                    latitude: settinglatitude
                  }
                }
              }
        }
      `)
    
        const {
            defaultUrl,
            defaultName,
            defaultDescription,
            streetAddress,
            addressLocality,
            addressRegion,
            postalCode,
            addressCountry,
            telephoneNumber,
            contactURL,
            longitude,
            latitude,
        } = site.childMarkdownRemark.frontmatter

        const {
            defaultLogo,
            defaultImage,
        } = site.childMarkdownRemark

    const writeData = () => {
        const scriptData = JSON.stringify(
            {
                "@context": "https://schema.org",
                "@type": "ProfessionalService",
                "description": defaultDescription,
                "additionalType": "https://en.wikipedia.org/wiki/Design",
                "@id": defaultUrl,
                "name": defaultName,
                "url": defaultUrl,
                "telephone": telephoneNumber,
                "image": defaultUrl+defaultImage.publicURL,
                "logo": defaultUrl+defaultLogo.publicURL,
                "address": {
                    "@type": "PostalAddress",
                    "name": defaultName+" Address",
                    "streetAddress": streetAddress,
                    "addressLocality": addressLocality,
                    "addressRegion": addressRegion,
                    "postalCode": postalCode,
                    "addressCountry": addressCountry,
                    "@id": contactURL
                },
                "geo": {
                    "@type": "GeoCoordinates",
                    "longitude": longitude,
                    "latitude": latitude,
                    "name": defaultName+" Geo Coordinates",
                },
                "openingHoursSpecification": [
                    {
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": [
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday"
                        ],
                        "opens": "08:30",
                        "closes": "05:30",
                        "name": "Opening Hours Mon-Fri",
                    }
                ],
            }
    )
        return(
            scriptData
        )}
    return(
        <>
        <Helmet>
            <script type="application/ld+json">
                {writeData()}
            </script>
        </Helmet>
        </>
    )
}

export default SDLocalBusiness