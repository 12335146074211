// Node Modules
import React, { useEffect, useState } from 'react'
import { Link, graphql, useStaticQuery } from "gatsby"

// Components

// Assets
import headerLogo from '../../images/nine-lives-logo-brand-lg-wide-wht.svg'
import headerLogo2 from '../../images/core-identity-nl-portait-wht.svg'

const Header = ({ location, isOpacity }) => {

  const [click, setClick] = useState(false)
  const navClick = () => setClick(!click)

  const [scroll, setScroll] = useState(false)
  const changeNav = () => {
    if (window.scrollY >= 100) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", changeNav, { passive: true })
    return () => {
      window.removeEventListener("scroll", changeNav, { passive: true })
    }
  }, [])

  let [showLogo, setShowLogo] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLogo(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  let isHomeLocation = false
  isHomeLocation = location.pathname.split('/')[1] === ""

  const { settings, menu } = useStaticQuery(graphql`
      query {
        settings: file(relativePath: {eq: "settings/general.md"}) {
            childMarkdownRemark {
              settinglogoLocal {
                childImageSharp {
                  gatsbyImageData
                }
                ext
                publicURL
              }
              frontmatter {
                settinglogoalt
                settingsiteorg
                settingtelephonenumber
                settingemail
              }
            }
          }
          menu: allMarkdownRemark(
            filter: {fileAbsolutePath: {regex: "/(menu)/.*[.]md$/"}}
            sort: {fields: frontmatter___menuposition, order: ASC}
          ) {
            edges {
              node {
                fields {
                  slug
                }
                id
                frontmatter {
                  menuname
                  menulink {
                    type
                    menupagelinktitle
                    menupagelink
                    menufeaturelinktitle
                    menufeaturelink
                    menucustomlinktitle
                    menucustomlink
                  }
                }
              }
            }
          }
      }
    `)

  const data = settings.childMarkdownRemark.frontmatter
  return (
    <>
      {isHomeLocation ?
        <div className="relative" style={{ height: "50vh", width: "50vw", display: "block", marginRight: "auto", marginLeft: "auto", marginTop: "20vh", marginBottom: "40vh", zIndex: "10"}}>
          <img src={headerLogo2} alt={data.settinglogoalt} 
            className={showLogo ? (scroll? "opacity-0" : "transition-all duration-4000 ease-in-out transform opacity-100") : ("transition-all duration-4000 ease-in-out transform opacity-0")}
            style={showLogo ? {width: "50vw", maxHeight: "50vh", display: "block", marginRight: "auto", marginLeft: "auto"} : {}}
          />
        </div>
      : ""}
      <header className={
        isOpacity ?
          scroll ? "bg-template-black100/100 text-white fixed top-0 z-50 h-20 transition duration-500 ease-in flex items-center shadow w-full" : "bg-template-black100/0 text-white fixed top-0 z-50 h-20 transition duration-500 ease-in flex items-center w-full"
          : "bg-template-black100/100 text-white fixed top-0 z-50 h-20 transition duration-500 ease-in flex items-center shadow w-full"}>
        <div className="container xs:px-4 sm:px-4 md:px-10 mx-auto flex py-2 flex-row justify-between">

        <div className="flex-1 inline-flex justify-start">
            <Link to="/" className="inline-flex font-medium">
              {isHomeLocation ?
                <img src={headerLogo} alt={data.settinglogoalt} className={showLogo ? (scroll ? ("transition-all duration-4000 ease-in-out transform opacity-100 max-h-16 w-44") : ("max-h-16 w-44 opacity-0")) : "max-h-16 w-44 opacity-0"} />
                :
                <img src={headerLogo} alt={data.settinglogoalt} className="max-h-16 w-44 opacity-100" />
              }
            </Link>
          </div>
          <div className="flex-1 inline-flex justify-end relative">
            <button className={click ? "openburger burgeropen" : "openburger"} onClick={navClick}>

              <span className="cls"></span>
              <span>
                <ul className="sub-menu">
                  {menu.edges.filter(edge => edge.node.frontmatter.menuname !== "dummy-menu-01-02-1999").map(edge => {
                    const menuitem = edge.node.frontmatter.menulink[0]
                    let menutitle = ''
                    let menuurl = ''
                    menuitem.menupagelinktitle && (menutitle = menuitem.menupagelinktitle)
                    menuitem.menufeaturelinktitle && (menutitle = menuitem.menufeaturelinktitle)
                    menuitem.menucustomlinktitle && (menutitle = menuitem.menucustomlinktitle)
                    menuitem.menupagelink && (menuurl = menuitem.menupagelink)
                    menuitem.menufeaturelink && (menuurl = menuitem.menufeaturelink)
                    menuitem.menucustomlink && (menuurl = menuitem.menucustomlink)
                    return (
                      <li key={edge.node.id}>
                        {!menuurl.startsWith("http") ?
                          <Link to={menuurl} onClick={navClick}>{menutitle}</Link>
                          :
                          <a href={menuurl} target="_blank" rel="noreferrer" onClick={navClick}>{menutitle}</a>
                        }
                      </li>
                    )
                  })}
                </ul>
              </span>
              <span className="cls"></span>
            </button>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header